import { T } from "@/locales";
export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "warehouse",
      meta: { title: T("仓库管理"), permission: "warehouse" },
      component: () => import("@/views/warehouse/Warehouse"),
    },
    {
      path: "reservoir_area",
      meta: { title: T("库区管理"), permission: "reservoir_area" },
      component: () => import("@/views/reservoirArea/index"),
    },
    {
      path: "stock_location",
      meta: { title: T("库位管理"), permission: "location" },
      component: () => import("@/views/stockLocation/StockLocation"),
    },
    {
      path: "client",
      meta: { title: T("货主管理"), permission: "client" },
      component: () => import("@/views/client/index"),
    },
    {
      path: "counterparty",
      meta: { title: T("往来单位"), permission: "counterparty" },
      component: () => import("@/views/counterparty/index"),
    },
    {
      path: "unit",
      meta: { title: T("单位管理"), permission: "unit" },
      component: () => import("@/views/unit/Unit"),
    },
    {
      path: "order_type",
      meta: { title: T("订单类型"), permission: "order_type" },
      component: () => import("@/views/orderType/OrderType"),
    },
    {
      path: "material",
      meta: { title: T("货品信息"), permission: "material" },
      component: () => import("@/views/material/Material"),
    },
    {
      path: "categories",
      meta: { title: T("货品分类"), permission: "category" },
      component: () => import("@/views/categories/Categories"),
    },

    {
      path: "material_batch",
      meta: { title: T("货品批次") },
      component: () => import("@/views/materialBatch/MaterialBatch"),
    },
    {
      path: "expiration_warning",
      meta: { title: T("临期预警"), permission: "batch" },
      component: () => import("@/views/expirationWarning/index"),
    },
    {
      path: "inventory_warning",
      meta: { title: T("库存预警"), permission: "inventory" },
      component: () => import("@/views/inventoryWarning/index"),
    },
    {
      path: "client_product_list",
      meta: { title: T("货品列表"), permission: "material" },
      component: () => import("@/views/clientProductList/index"),
    },
  ],
};
